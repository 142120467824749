@use '~include-media/dist/_include-media.scss' as includeMedia;

.smc-TrackingDeliveryCustomerInfos {
    &-wrapper {
        display: flex;
        flex-direction: column;

        @include includeMedia.media('>=desktop') {
            flex-direction: row;
        }
    }

    &-col {
        width: 50%;
    }
}