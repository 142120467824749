@use '../../variables' as variables;

.smc-TrackingDeliveryOrderStatus {
    &-item {
        border-bottom: 1.2rem solid variables.$lightGrey;

        &:last-child {
            border-bottom: none;
        }
    }
}