@use '../../variables' as variables;

.smc-TrackingDeliverySpinner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    &-wrapper {
        width: 9rem;
        text-align: center;
    
        &  > div {
            width: 2.3rem;
            height: 2.3rem;
            background-color: variables.$darkGrey;
          
            border-radius: 100%;
            display: inline-block;
            animation: sk-bouncedelay 1.4s infinite ease-in-out both;
          }
    
          .bounce1 {
            animation-delay: -0.32s;
          }
    
          .bounce2 {
            animation-delay: -0.16s;
          }
    }

}
  
@-webkit-keyframes sk-bouncedelay {
0%, 80%, 100% { -webkit-transform: scale(0) }
40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
} 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
}
}