@use '../variables' as variables;

.smc-TrackingDelivery {
    font-family: 'Nunito', sans-serif;
    color: variables.$darkBlue;
    &-container {
        width: 100%;
        max-width: 1320px;
        padding: 0 2.2rem;
        margin: 15rem auto 0 auto;

        &--iframe {
            margin: 5rem auto 0 auto;
        }
    }
}