@use '~include-media/dist/_include-media.scss' as includeMedia;

.smc-TrackingDeliveryOrderTracking {
    &-wrapper{
        max-width: 665px;
        margin: 0 auto;

        @include includeMedia.media('>=desktop') {
            max-width: 995px;

            &--overflow {
                overflow: auto;
            }
        }
    }
}