@use '../../variables' as variables;

.smc-TrackingDelivery-card {
    border: 1px solid variables.$lightGrey;
    padding: 1.8rem;
    margin-bottom: 4.8rem;

    &--noPadding {
        padding: 0;
    }
}