@use '../../variables' as variables;
@use '~include-media/dist/_include-media.scss' as includeMedia;

.smc-TrackingDeliveryHeader {
    width: 100%;
    height: 12rem;
    display: flex;
    padding: 0 2.2rem;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    background-color: variables.$white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

    &-logo {
        width: 27rem;
        height: auto;
    }

    &-closeTabBtn {
        display: none;
        align-items: center;
        cursor: pointer;

        @include includeMedia.media('>=tablet') {
           display: flex;
        }
        
        &Txt {
            display: inline-block;
            margin-right: 1rem;
            color: variables.$red;
            font-size: 1.6rem;
        }
    }
}