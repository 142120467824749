@use '../../variables' as variables;

.smc-TrackingDeliveryLineOrderTable {
    max-width: 100%;
    overflow: auto;
    border-bottom: 1px solid variables.$lightGrey;
    padding: 1.6rem;

    &-table {
        width: 100%;
    }

    &-titleCell {
        font-weight: 700;
        font-size: 1.4rem;

        &--bigger {
            min-width: 30rem;
        }
    }

    &-cell {
        font-size: 1.6rem;
        &--bolder {
            font-weight: 900;
        }
        
        &--centered {
            text-align: center;
        }
    }
}