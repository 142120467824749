@use '../../variables' as variables;
@use '../../mixins' as mixins;

.smc-TrackingDeliveryLogisticFlow {
    &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.6rem;
    }

    &-status {
        font-weight: 700;
        font-size: 2.2rem;

        &Txt {
            display: block;
            margin-right: 2.4rem;

            &--success {
                color: variables.$green;
            }

            &--error {
                color: variables.$red;
            }
        }
    }

    &-openDetailIcon {
        transition: transform .2s ease-in-out;
        position: relative;
        z-index: -1;
    }

    &-openDetails {
        @include mixins.button-reset;
        font-size: 1.6rem;
        color: variables.$ocre;
        display: flex;
        align-items: center;

        &Txt {
            margin-right: 1.4rem;
        }

        &--opened {
            .smc-TrackingDeliveryLogisticFlow-openDetailIcon {
                transform: rotate(180deg);
            }
        }
    }

}