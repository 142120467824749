@use '~include-media/dist/_include-media.scss' as includeMedia;

.smc-TrackingDeliverykeyValueInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.6rem;
    
    @include includeMedia.media('>=tablet') {
        flex-direction: row;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &-key,
    &-value {
        display: inline-block;
        font-size: 1.6rem;
    }

    &-key {
        min-width: 18rem;
        font-weight: 700;
        margin-right: 1rem;
    }

    &--adress {
       .smc-TrackingDeliverykeyValueInfo-value {
           max-width: 25rem;
           white-space: break-spaces;
       } 
    }
}