@import "./reboot.scss";

//Import Global font
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

//Import titles font
@font-face {
  font-display: swap;
  font-family: "Glendale";
  src: url("./fonts/glendale/glendale-regular-webfont.woff2") format("woff2"),
    url("./fonts/glendale/glendale-regular-webfont.woff") format("woff");
}
html,
body,
#root {
  min-height: 100%;
}

.smc-TrackingDelivery-title {
  font-family: Glendale, sans-serif;
  text-transform: uppercase;
  font-size: 3.2rem;
  font-weight: 400;
  margin-bottom: 2.2rem;
}
