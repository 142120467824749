@use '../../variables' as variables;

.smc-TrackingDeliveryMilestoneCard {
    position: relative;
    flex: 0 0 16.666667%;
    padding: 1.6rem;
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    &::after {
        content: ' ';
        position: absolute;
        top: 16px;
        right: -2px;
        height: 90%;
        width: 1px;
        background-color: variables.$lightGrey;
    }
    &::before {
        content: ' ';
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: variables.$lightGrey;
    }

    &-bottom {
        min-height: 18.3rem;
    }
    &-title {
        font-weight: 700;
        font-size: 1.6rem;
        margin-bottom: 3rem;
    }

    &-status {
        display: flex;
        align-items: center;

        &--error {
            color: variables.$red;
        }

        &--success {
            color: variables.$green;
        }

        &--waiting {
            color: variables.$darkGrey;
        }

        &--inprogress {
            color: variables.$ocre
        }

        &Txt {
            font-weight: 700;
            font-size: 1.8rem;
            margin-left: 1rem;
        }
    }

    &-date {
        margin-top: 1.6rem;
        &Title,
        &Value {
            display: block;
        }

        &Title {
            font-weight: 700;
            font-size: 1.4rem;
        }

        &Value {
            font-size: 1.6rem
        }
    }
}