@use '../../variables' as variables;

.smc-TrackingDeliveryOrderApiError {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    &-txt {
        font-family: 'Nunito', sans-serif;
        color: variables.$darkBlue;
        font-size: 1.6rem;
        font-weight: 800;
        text-transform: uppercase;
        white-space: break-spaces;
        text-align: center;
    }
}