.smc-TrackingDeliveryMilestoneDetails {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

    &--opened {
        max-height: 10000px;
        transition: max-height 1s ease-in-out;
    }

    &-wrapper {
        padding: 0.3rem 1.6rem;
    }

    &-milestoneGrid {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}