@use '../../variables' as variables;
@use '~include-media/dist/_include-media.scss' as includeMedia;

.smc-TrackingDeliveryTimeLine {
    min-height: 14rem;
    margin: 2rem 0 2rem 0;
    display: flex;

    @include includeMedia.media('>=tablet') {
       display: block;
        padding-top: 70px;
       margin: 2rem 0 0 0;

       &--overflow {
           overflow: auto;
       }
    }

    &-steper {
        display: flex;
        flex-direction: column;

        @include includeMedia.media('>=tablet') {
            flex-direction: row;
            justify-content: center;

            &--inlineFlex {
                display: inline-flex;
            }
        }
    }

    &-step {
        width: 100%;
        height: 8rem;
        display: flex;
        align-items: center;
        flex-direction: column;

        @include includeMedia.media('>=tablet') {
            width: 16rem;
            height: auto;
            flex-direction: row;
            justify-content: center;
        }

        &:first-child {
            flex: 0;
        }

        &:not(:first-child)::before {
            content: ' ';
            display: block;
            width: 0.8rem;
            height: 100%;
            background-color: variables.$lightGrey;

            @include includeMedia.media('>=tablet') {
                width: 100%;
                height: 0.8rem;
            }
        }

        &--active {
            .smc-TrackingDeliveryTimeLine-bubble {
                background-color: variables.$ocre;
            }

            &:not(:first-child)::before {
                background-color: variables.$ocre;
            }
        }
    }

    &-bubble {
        position: relative;
        flex: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        background-color: variables.$lightGrey;
        z-index: -1;

        &Txt {
            width: 20rem;
            position: absolute;
            top: 50%;
            display: flex;
            flex-direction: column;
            left: 4.2rem;
            font-size: 1.2rem;
            transform: translate(0,-50%);

            span:first-child {
                font-weight: bold;
            }

            span:last-child {
                font-weight: lighter;
                font-size: 1rem;
            }

            @include includeMedia.media(">tablet","<=desktop") {
                width: 14rem!important;
            }

            @include includeMedia.media('>=tablet') {
                width: 15rem;
                left: 50%;
                top: -6rem;
                transform: translate(-50%, 0);
                text-align: center;
             }
        }
    }
}
